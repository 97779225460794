import { Controller } from "stimulus"
import { disableImgModal } from "../core"

const TIMEOUT = 2000

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.template = this.containerTarget.children[0]
    this.containerTarget.innerHTML = ""
    this.handleImgError()
  }

  handleImgError() {
    const defaultImg = document.querySelector("#defaultImg")
    if (!defaultImg) {
      return 0
    }
    const imgs = document.querySelectorAll("img")
    for (let i = 0; i < imgs.length; i++) {
      const img = imgs[i]
      this.checkIfImageExists(img.src, (exists) => {
        if (!exists) {
          img.src = defaultImg.getAttribute("src")
          disableImgModal(img)
          if (img.classList.contains("homepage-image")) {
            img.parentElement.style.width = "56.3%"
          }
          if (img.classList.contains("granite-image")) {
            img.style.position = "absolute"
            img.style.bottom = "-50px"
            img.style.left = "-95px"
          }
          if (img.getAttribute("data-remove-image") === "true") {
            img.parentElement.removeChild(img)
          }
        }
      })
    }
  }

  // CHECK IF IMAGE EXISTS
  checkIfImageExists(url, callback) {
    const img = new Image()
    img.src = url

    if (img.complete) {
      callback(true) // eslint-disable-line
    } else {
      img.onload = () => {
        callback(true) // eslint-disable-line
      }

      img.onerror = () => {
        callback(false) // eslint-disable-line
      }
    }
  }

  add(event) {
    let type, message

    if (event.type.startsWith("ajax:")) {
      if (event.detail[0].status < 400) {
        type = "success"
        message = event.currentTarget.dataset.successMessage
      } else {
        type = "error"
        message = event.currentTarget.dataset.errorMessage
      }
    } else if (event.type.startsWith("submit:")) { // DEPRECATED
      type = event.detail.type
      message = event.detail.message
    } else {
      throw new Error(`BUG: unknown event type: ${event.type}`)
    }

    this.displayMessage(type, message, event.currentTarget.dataset.timeout)
  }

  handleButtonClick(event) {
    const type = event.currentTarget.dataset.type || "success"
    const message = event.currentTarget.dataset.message || "Operation completed successfully"
    const timeout = event.currentTarget.dataset.timeout || TIMEOUT

    this.displayMessage(type, message, timeout)
  }

  displayMessage(type, message, timeout = TIMEOUT) {
    const node = this.renderTemplate(type, message)
    this.containerTarget.appendChild(node)
    this.containerTarget.classList.add("is-open")
    window.setTimeout(() => this.remove(node), timeout)
  }

  remove(node) {
    node.remove()

    if (this.containerTarget.children.length === 0) {
      this.containerTarget.classList.remove("is-open")
    }
  }

  renderTemplate(type, message) {
    if (!this.template || !this.template.nodeType) {
      let flashMessageElement = document.createElement("div")
      flashMessageElement.className = "flash-message"
      this.containerTarget.appendChild(flashMessageElement)
      this.template = this.containerTarget.children[0]
    }
    const node = document.importNode(this.template, true)
    node.classList.add(`is-${type}`)
    node.textContent = message
    return node
  }
}

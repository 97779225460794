import { Controller } from "stimulus"
import { post } from "../lib/request"

export default class extends Controller {
  connect() {
    this.updateSelectOptions()
  }

  updateSelectOptions() {
    const dimensionSelect = this.element
    const dimensionSelected = dimensionSelect.value
    if (dimensionSelected) {
      const curentCells = this.calculateCells(dimensionSelected)
      dimensionSelect.options.forEach(option => {
        const cells = this.calculateCells(option.value)
        if (curentCells > cells) {
          option.disabled = true
          option.style.backgroundColor = "#FF9999"
        }
      })
    }
  }

  toggleDisplay(event) {
    let elmCheckbox = document.getElementById(event.currentTarget.id)
    if (elmCheckbox.checked) {
      elmCheckbox.closest(".box").style.opacity = 0.3
      elmCheckbox.checked = true
    } else {
      elmCheckbox.closest(".box").style.opacity = 1
      elmCheckbox.checked = false
    }
  }

  adjust(event) {
    const size = event.currentTarget.value
    const dimension = event.currentTarget.dataset.dimension
    const elmBox = document.getElementById(event.currentTarget.id)
    const parts = size.split("x")
    const numberOfColumns = parseInt(parts[0]) || 0
    const numberOfRows = parseInt(parts[1]) || 0
    if (numberOfColumns < 1 || numberOfRows < 1) {
      resetBox()
      event.currentTarget.value = "1x1"
      return
    }

    const maxDimension = dimension.startsWith("4") ? 2 : 3
    switch (dimension) {
      case "4x3":
      case "5x3":
      case "5x4":
        if (numberOfColumns > maxDimension || numberOfRows > maxDimension) {
          resetBox()
          event.currentTarget.value = "1x1"
        } else {
          setBoxSpan()
        }
        break

      default:
        resetBox()
        event.currentTarget.value = "1x1"
    }

    function resetBox() {
      alert("Veuillez choisir une dimension compatible avec la grille actuelle.")
      elmBox.closest(".box").style.gridColumn = "span 1"
      elmBox.closest(".box").style.gridRow = "span 1"
    }

    function setBoxSpan() {
      elmBox.closest(".box").style.gridColumn = `span ${numberOfColumns}`
      elmBox.closest(".box").style.gridRow = `span ${numberOfRows}`
    }
  }

  setDimension(event) {
    const newDimension = event.currentTarget.value
    const oldDimension = event.currentTarget.dataset.dimension
    const componentId = event.currentTarget.dataset.component
    const numberCells = event.currentTarget.dataset.cells
    this.compareAndSaveDimensions(newDimension, oldDimension, componentId, numberCells, event)
  }

  compareAndSaveDimensions(newDimension, oldDimension, componentId, numberCells, event) {
    const newCells = this.calculateCells(newDimension)
    const oldCells = this.calculateCells(oldDimension)
    if (newCells < oldCells) {
      alert("La nouvelle grille est inférieure à la grille actuelle !")
    } else {
      if (window.confirm("Voulez-vous vraiment effectuer cette action ?\nNe pas oublier de paramétrer les nouvelles box.")) {
        const cellsToAdd = newCells - oldCells
        let formData = new FormData()
        formData.append("componentId", componentId)
        formData.append("numberCells", numberCells)
        formData.append("cellsToAdd", cellsToAdd)
        formData.append("newDimension", newDimension)
        const admin = window.ADMIN_PATH
        post(admin + "/component/updateGrids", formData).then(response => {
          if (response.ok) {
            location.reload()
          } else {
            alert("Erreur!")
            event.currentTarget.value = oldDimension
          }
        })
      } else {
        event.currentTarget.value = oldDimension
      }
    }
  }

  setCatalogType(target, admin) {
    const { componentId } = target.dataset
    const currentType = document.querySelector("input[name='catalog_type']:checked").value
    let typeData = {
      componentId,
      catalog_type: currentType
    }
    return post(admin + "/component/updateCatalogType", typeData)
  }

  updateBoxes(target, admin) {
    const _event = target.id
    let formData = new FormData()
    let data = []
    let i = 1
    while (i <= 20) {
      if (document.getElementById("box-" + i + "-id")) {
        data.push({
          id: document.getElementById("box-" + i + "-id").value,
          size: document.getElementById("box-" + i + "-size").value,
          visibility: !document.getElementById("box-" + i + "-visibility").checked
        })
      }
      i++
    }
    formData.append("data", JSON.stringify(data))
    post(admin + "/component/updateConfig", formData).then(response => {
      if (_event === "save") {
        location.reload()
      } else {
        location.replace(admin + "/components")
      }
    })
  }

  save(event) {
    const componentSubmit = document.getElementById("componentSubmit")
    if (componentSubmit) {
      componentSubmit.querySelectorAll("input[type='submit']").forEach(submitButton => submitButton.disabled = true)
    }

    const admin = window.ADMIN_PATH
    const currentTarget = event.currentTarget
    this.setCatalogType(currentTarget, admin).then(resp => {
      this.updateBoxes(currentTarget, admin)
    })
  }

  calculateCells(dimension) {
    const [columns, rows] = dimension.split("x").map(Number)
    return columns * rows
  }

  getPathName(event) {
    const url = new URL(event.currentTarget.value)
    event.currentTarget.value = url.pathname
  }
}
